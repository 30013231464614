<template>
  <div class="radio-button-container radio-button-container--horizontal">
    <!-- Date picker Option -->
    <InputTextDate
      v-model="dateSelected"
      :name="name"
      :style="'width: var(--button-width);'"
      class="radio-date-picker"
      @focus="isErrorDate = false"
      @blur="validateDate"
      :error="isErrorDate"
    />
    <!-- Second option  -->
    <InputRadio
      v-model="dateSelected"
      :class="buttonClass"
      :name="name"
      :value="optionDate.value"
      :label="optionDate.label"
      :checked="dateSelected === optionDate.value"
      @change="validateDate"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";

import InputTextDate from "@/components/UI/InputTextDate.vue";
import InputRadio from "@/components/UI/Base/InputRadio.vue";

export default {
  components: { InputTextDate, InputRadio },

  props: {
    modelValue: String,
    name: String,
    optionDate: {
      type: Object,
      default: () => ({
        value: "",
        label: "",
      }),
    },
  },

  emits: ["update:modelValue", "update:isValidDate"],

  setup(props, { emit }) {
    const dateSelected = ref(props.modelValue);

    // Two-way binding
    watch(dateSelected, (newDateSelected) =>
      emit("update:modelValue", newDateSelected)
    );
    watch(
      () => props.modelValue,
      (newDateSelected) => (dateSelected.value = newDateSelected)
    );

    // Validation on emptyness
    const isErrorDate = ref(false);
    const validateDate = () => {
      isErrorDate.value = !isValidDate.value;
    };

    const isValidDate = computed(() => {
      return !(/d|m|y|_/.test(dateSelected.value) || !dateSelected.value);
    });
    watch(isValidDate, (newIsValid) => emit("update:isValidDate", newIsValid), {
      immediate: true,
    });

    const buttonClass = computed(() => {
      return [
        "radio-button--height-small",
        "radio-button--width-small",
        "radio-button--dot-hide",
        "radio-button--date-picker",
      ];
    });

    return {
      dateSelected,
      buttonClass,
      isErrorDate,
      validateDate,
      isValidDate,
    };
  },
};
</script>

<style scoped>
.radio-button-container {
  display: flex;
  flex-flow: column;
  row-gap: 30px;
}
.radio-button-container--horizontal {
  flex-flow: row;
  column-gap: var(--radio-button-horizontal--column-gap);
}

.radio-date-picker :deep(.text-input__field) {
  padding: 0 1px 2px 19px;
}
.radio-date-picker :deep(.text-input__field):hover {
  padding: 0 0 2px 18px;
}
.radio-date-picker :deep(.text-input__field):focus {
  padding: 0 0 2px 18px;
}
</style>
