<template>
  <InputTextTextarea
    :name="'schedule-comments'"
    :label="'Komientarz do grafiku'"
  />
</template>

<script>
export default {
  components: {},
  props: {},
  setup() {
    return {};
  },
};
</script>

<style>
/* For styles loook into InputText < style > block */
</style>
