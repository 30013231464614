<template>
  <div class="content">
    <OfferNannyInputSchedule
      v-model:schedule-value="offerNanny.schedule.value"
      v-model:schedule-comment="offerNanny.schedule.comment"
      v-model:schedule-date-start="offerNanny.schedule.dateStart"
      v-model:period-term="offerNanny.schedule.period.term"
      v-model:period-measure="offerNanny.schedule.period.measure"
      v-model:period-amount="offerNanny.schedule.period.amount"
      @update:is-valid="isValid = $event"
    />

    <ButtonPrimaryNext
      :to="{ name: 'OfferNannyCreatePerks' }"
      :isDisabled="!isValid"
    />
  </div>
</template>

<script>
import { useOfferNannyStore } from "@/stores/offerNanny";
import { ref } from "@vue/reactivity";

import OfferNannyInputSchedule from "@/components/Offer/Nanny/OfferNannyInputSchedule.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";

export default {
  components: {
    OfferNannyInputSchedule,
    ButtonPrimaryNext,
  },

  setup() {
    const offerNanny = useOfferNannyStore();

    const isValid = ref(false);

    return {
      offerNanny,
      isValid,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 120px;
}

@media (max-width: 1200px) {
}
</style>
