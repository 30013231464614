<template>
  <div class="container-schedule">
    <!-- SCHEDULE SECTION -->
    <div class="section container-schedule__section">
      <div class="block section__block section__block--h1">
        <BaseH1 :text="'Wybierz pory dnia'" />
        <TextDescription :text="'Kiedy potrzebujesz niani?'" />
      </div>

      <div class="block section__block section__block--schedule">
        <ScheduleInput
          v-model="schedule.value"
          @update:is-valid-schedule="schedule.isValid = $event"
        />
        <InputTextTextareaScheduleComment
          v-model="schedule.comment"
          class="comment section__block--schedule__comment"
        />
      </div>
    </div>

    <!-- START DATE SECTION -->
    <div class="section container-schedule__section">
      <div class="block section__block section__block--h1">
        <BaseH1
          :text="'Kiedy niania musi zacząć pracę?'"
          class="h1 block__h1"
        />
      </div>

      <div class="block section__block section__block--date-start">
        <InputRadioGroupDatePicker
          v-model="schedule.dateStart.string"
          @update:is-valid-date="schedule.dateStart.isValid = $event"
          :name="'date-start'"
          :option-date="optionDateToday"
        />
      </div>
    </div>

    <!-- PERIOD SECTION -->
    <div class="section container-schedule__section">
      <div class="block section__block section__block--h1">
        <BaseH1 :text="'Na jaki okres szukasz niani?'" />
      </div>
      <InputRadioGroupVertical
        v-model="schedule.period.term"
        :name="'period-term'"
        :options="periodTermOptions"
      />
      <PeriodMeasureInputRadioGroupHorizontal
        v-show="schedule.period.term === 'custom'"
        v-model="schedule.period.measure"
        :name="'period-measure'"
        :measure-options="periodMeasureOptions"
      />
      <PeriodAmountInputNumberSpinner
        v-show="schedule.period.term === 'custom' && schedule.period.measure"
        v-model="schedule.period.amount"
        :name="'period-amount'"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import { dateToString, getTodayDateString, stringToDate } from "@/helpers";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import InputTextTextareaScheduleComment from "@/components/UI/InputTextTextareaScheduleComment.vue";
import ScheduleInput from "@/components/ScheduleInput.vue";
import PeriodMeasureInputRadioGroupHorizontal from "@/components/PeriodMeasureInputRadioGroupHorizontal.vue";
import PeriodAmountInputNumberSpinner from "@/components/PeriodAmountInputNumberSpinner.vue";
import InputRadioGroupVertical from "@/components/UI/Base/InputRadioGroupVertical.vue";
import InputRadioGroupDatePicker from "@/components/UI/InputRadioGroupDatePicker.vue";

export default {
  components: {
    BaseH1,
    TextDescription,
    InputTextTextareaScheduleComment,
    ScheduleInput,
    PeriodMeasureInputRadioGroupHorizontal,
    PeriodAmountInputNumberSpinner,
    InputRadioGroupVertical,
    InputRadioGroupDatePicker,
  },

  props: {
    scheduleValue: Array,
    scheduleComment: String,
    scheduleDateStart: [String, Date],
    periodTerm: String,
    periodMeasure: String,
    periodAmount: Number,
  },

  emits: [
    "update:scheduleValue",
    "update:scheduleComment",
    "update:scheduleDateStart",
    "update:periodTerm",
    "update:periodMeasure",
    "update:periodAmount",
    "update:isValid",
  ],

  setup(props, { emit }) {
    const schedule = reactive({
      value: props.scheduleValue,
      isValid: false,
      comment: props.scheduleComment,
      dateStart: {
        string: "",
        date: null,
        isValid: false,
      },
      period: {
        term: props.periodTerm,
        measure: ["months", "weeks"].includes(props.periodMeasure)
          ? props.periodMeasure
          : "months",
        amount: props.periodAmount,
        get isValid() {
          switch (this.term) {
            case "custom":
              return ["months", "weeks"].includes(this.measure);

            case "long_term":
              return true;
            default:
              return false;
          }
        },
      },
    });

    // Way out bindings
    watch(
      () => schedule.value,
      (newScheduleValue) => emit("update:scheduleValue", newScheduleValue),
      { deep: true }
    );
    watch(
      () => schedule.comment,
      (newComment) => emit("update:scheduleComment", newComment)
    );
    watch(
      () => schedule.dateStart.date,
      (newDate) => {
        if (
          props.scheduleDateStart &&
          dateToString(props.scheduleDateStart) === dateToString(newDate)
        ) {
          return;
        }

        emit("update:scheduleDateStart", newDate);
      }
    );
    watch(
      () => schedule.period.term,
      (newPeriodTerm) => emit("update:periodTerm", newPeriodTerm)
    );
    watch(
      () => schedule.period.measure,
      (newPeriodMeasure) => emit("update:periodMeasure", newPeriodMeasure),
      { immediate: true }
    );
    watch(
      () => schedule.period.amount,
      (newPeriodAmount) => emit("update:periodAmount", newPeriodAmount)
    );

    // Way in bindings
    watch(
      () => props.scheduleValue,
      (newScheduleValue) => (schedule.value = newScheduleValue),
      { deep: true }
    );
    watch(
      () => props.scheduleComment,
      (newComment) => (schedule.comment = newComment)
    );
    watch(
      () => props.scheduleDateStart,
      (newDate) => {
        if (!newDate) return;

        schedule.dateStart.string = dateToString(newDate);
      },
      { immediate: true }
    );
    watch(
      () => props.periodTerm,
      (newPeriodTerm) => (schedule.period.term = newPeriodTerm)
    );
    watch(
      () => props.periodMeasure,
      (newPeriodMeasure) => (schedule.period.measure = newPeriodMeasure)
    );
    watch(
      () => props.periodAmount,
      (newPeriodAmount) => (schedule.period.amount = newPeriodAmount)
    );

    // Component inside binding for dateStart
    watch(
      () => schedule.dateStart.string,
      (newDateString) => {
        // Validate new dob string
        if (/d|m|y|_/.test(newDateString) || newDateString.length === 0) return;

        if (
          schedule.dateStart.date &&
          dateToString(schedule.dateStart.date) === newDateString
        ) {
          return;
        }

        const newDate = stringToDate(newDateString);
        schedule.dateStart.date = newDate;
      }
    );

    const isValid = computed(() => {
      return (
        schedule.dateStart.isValid &&
        schedule.isValid &&
        schedule.period.isValid
      );
    });
    watch(isValid, (newIsValid) => emit("update:isValid", newIsValid), {
      immediate: true,
    });

    const periodMeasureOptions = [
      {
        label: "Tygodnie",
        value: "weeks",
      },
      {
        label: "Miesięcy",
        value: "months",
      },
    ];

    const periodTermOptions = [
      {
        label: "Na długi",
        value: "long_term",
      },
      {
        label: "Określić",
        value: "custom",
      },
    ];

    const optionDateToday = {
      label: "Niezwłocznie",
      value: getTodayDateString(),
    };

    return {
      schedule,
      isValid,
      periodTermOptions,
      periodMeasureOptions,
      optionDateToday,
    };
  },
};
</script>

<style scoped>
.container-schedule {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 120px;
}
.container-schedule__section {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 70px;
}
.section__block {
  display: flex;
}
.section__block--h1 {
  width: 360px;
  row-gap: 20px;
  flex-flow: column;
  align-items: center;
}
.section__block--schedule {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  column-gap: 40px;
  row-gap: 40px;
}
.section__block--schedule .section__block--schedule__comment {
  width: 360px;
}
@media (max-width: 1200px) {
  .section__block--h1 {
    width: 100%;
    row-gap: 20px;
    flex-flow: column;
    align-items: center;
  }
}
</style>
