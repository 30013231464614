<template>
  <div class="period-measure">
    <base-text class="label period-measure__label">Przez</base-text>
    <InputRadioGroupHorizontal
      :name="name"
      :options="measureOptions"
      @update:model-value="$emit('update:modelValue', $event)"
      :model-value="modelValue"
      :width-small="true"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    measureOptions: {
      type: Array,
      required: true,
    },
  },
  emits: ["update:modelValue"],
};
</script>
<style scoped>
.period-measure {
  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
.period-measure__label {
  font: var(--input__label--font);
  text-align: center;
}
</style>
