<template>
  <div class="period-amount">
    <base-text class="label period-amount__label">Ile?</base-text>
    <BaseInputNumberSpinner
      :name="name"
      :max="40"
      :min="1"
      @update:model-value="$emit('update:modelValue', $event)"
      :model-value="modelValue"
    />
  </div>
</template>

<script>
import BaseInputNumberSpinner from "./UI/Base/BaseInputNumberSpinner.vue";

export default {
  components: { BaseInputNumberSpinner },
  props: {
    modelValue: Number,
    name: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
};
</script>

<style scoped>
.period-amount {
  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
.period-amount__label {
  font: var(--input__label--font);
  text-align: center;
}
</style>
