<template class="container">
  <InputText
    :type="'text'"
    :autocomplete="'off'"
    :spellcheck="false"
    :small="true"
    :placeholder="'dd / mm / yyyy'"
    :mask="maskRef"
    @focus="onFocus"
    class="text-input-parent--date"
  />
</template>

<script>
import useIMaskDate from "@/composables/imask/useIMaskDate";
import useActivateIMaskPattern from "@/composables/imask/useActivateIMaskPattern";

export default {
  setup() {
    const { maskRef } = useIMaskDate();
    const { activateIMaskPattern } = useActivateIMaskPattern();
    const onFocus = () => {
      activateIMaskPattern(maskRef);
    };

    return {
      maskRef,
      onFocus,
    };
  },
};
</script>

<style scoped>
/* For styles loook into InputText < style > block */
.text-input-parent--date :deep(.text-input__field) {
  padding: 0 15px 1px 24px;
}
.text-input-parent--date :deep(.text-input__field):hover {
  padding: 0 14px 1px 23px;
}
.text-input-parent--date :deep(.text-input__field):focus {
  padding: 0 14px 1px 23px;
}

@media (max-width: 1200px) {
  .text-input-parent--date :deep(.text-input__field) {
    padding: 0 15px 1px 24px;
  }
  .text-input-parent--date :deep(.text-input__field):hover {
    padding: 0 14px 1px 23px;
  }
  .text-input-parent--date :deep(.text-input__field):focus {
    padding: 0 14px 1px 23px;
  }
}
</style>
