import { ref } from "vue";
import IMask from "imask/esm/imask"; // imports only factory

export default function useIMaskDate() {
  const maskRef = ref({
    mask: "dd {/} `mm {/} {2\\0}`yy",
    lazy: true,
    overwrite: true,
    blocks: {
      dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
        autofix: true, // bound value
        placeholderChar: "d",
      },
      mm: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
        autofix: true, // bound value
        placeholderChar: "m",
      },
      yy: {
        mask: IMask.MaskedRange,
        from: 21,
        to: 99,
        maxLength: 2,
        autofix: true, // bound value
        placeholderChar: "y",
      },
    },
  });

  return {
    maskRef,
  };
}
