<template>
  <div class="schedule-container">
    <div
      class="wrapper-vertical schedule-container__wrapper-vertical schedule-container__wrapper-vertical--row-titles"
    >
      <div
        class="row-title wrapper-vertical__row-title cell wrapper-vertical__cell"
      ></div>
      <div
        class="row-title wrapper-vertical__row-title cell wrapper-vertical__cell"
      >
        Pn
      </div>
      <div
        class="row-title wrapper-vertical__row-title cell wrapper-vertical__cell"
      >
        Wt
      </div>
      <div
        class="row-title wrapper-vertical__row-title cell wrapper-vertical__cell"
      >
        Śr
      </div>
      <div
        class="row-title wrapper-vertical__row-title cell wrapper-vertical__cell"
      >
        Cz
      </div>
      <div
        class="row-title wrapper-vertical__row-title cell wrapper-vertical__cell"
      >
        Pt
      </div>
      <div
        class="row-title wrapper-vertical__row-title cell wrapper-vertical__cell"
      >
        Sb
      </div>
      <div
        class="row-title wrapper-vertical__row-title cell wrapper-vertical__cell"
      >
        Nd
      </div>
    </div>
    <div
      v-for="(dayPart, name, j) in dayParts"
      :key="j"
      class="wrapper-vertical schedule-container__wrapper-vertical"
    >
      <div
        class="column-title wrapper-vertical__column-title cell wrapper-vertical__cell"
      >
        {{ dayPart }}
      </div>
      <input
        v-for="i in 7"
        :key="(i - 1).toString() + j.toString()"
        v-model="schedule"
        type="checkbox"
        :name="name"
        :value="(i - 1).toString() + j.toString()"
        class="checkbox wrapper-vertical__checkbox cell wrapper-vertical__cell"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

export default {
  props: {
    modelValue: Array,
  },

  emits: ["update:modelValue", "update:isValidSchedule"],

  setup(props, { emit }) {
    const schedule = ref(props.modelValue);

    // Two-way binding for schedule.value
    watch(
      () => props.modelValue,
      (newSchedule) => (schedule.value = newSchedule)
    );
    watch(schedule, (newSchedule) => emit("update:modelValue", newSchedule));

    // Validation for emptiness
    const isValidSchedule = computed(() => {
      return !!schedule.value.length;
    });
    watch(
      isValidSchedule,
      (newIsValid) => emit("update:isValidSchedule", newIsValid),
      { immediate: true }
    );

    const dayParts = {
      morning: "Rano",
      afternoon: "Dzien",
      evening: "Wieczór",
      night: "Noc",
    };

    return {
      schedule,
      dayParts,
      isValidSchedule,
    };
  },
};
</script>

<style scoped>
.schedule-container {
  width: 360px;
  height: fit-content;
  display: flex;

  background-color: rgba(227, 170, 229, 0.3);
  border-radius: 10px;

  font: var(--schedule-title--font);
}
.schedule-container__wrapper-vertical {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 72px;
  flex-grow: 1;
}
.schedule-container__wrapper-vertical--row-titles {
  width: 43px;
  flex-grow: 0;
}

.wrapper-vertical__cell {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-right: 2px solid #fff;
  border-bottom: 2px solid #5fb9fb;

  cursor: pointer;
}
.wrapper-vertical__cell:last-child {
  border-bottom: unset;
}
.schedule-container__wrapper-vertical:last-child .wrapper-vertical__cell {
  border-right: unset;
}

.wrapper-vertical__checkbox:after {
  margin-top: auto;
  margin-bottom: auto;

  width: 20px;
  height: 20px;
  border-radius: 30px;
  box-sizing: border-box;

  position: absolute;

  background-color: var(--main-bg-color);
  content: "";
  display: block;
  visibility: visible;
  border: var(--button-tertiary-border);
}

.wrapper-vertical__checkbox:hover:after {
  border: var(--buton-tertiary-hover-border);
  background-color: rgba(22, 147, 155, 0.3);
  box-shadow: inset 0px 0px 0px 4px var(--main-bg-color);
}

.wrapper-vertical__checkbox:checked:after {
  border: var(--buton-tertiary-hover-border);
  background-color: var(--button-font-color);
  box-shadow: inset 0px 0px 0px 4px var(--main-bg-color);
}

@media (max-width: 1200px) {
  .schedule-container {
    width: 100%;
  }
}
</style>
